<template>
  <div>
    <div
      v-for="(snackbar, position) in snackbars"
      :class="{ 'c-notification--error': snackbar.isError }"
      :key="position"
      :ref="`notification-${position}`"
      data-test="snackbar"
      class="notification c-notification"
    >
      <span
        class="c-notification__close c-icon c-icon--base c-icon--close"
        @click="close(position, snackbar.close)"
      />
      <span>{{ snackbar.message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    snackbars() {
      return window.STORE.state.snackbar.snackbars;
    },
  },
  methods: {
    close(position, callback) {
      this.$refs[`notification-${position}`][0].classList.add('hidden');
      callback();
    },
  },
};
</script>

<style scoped>
.notification {
  width: 480px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 180px;
  animation: slideDown 5s ease 2s 1 forwards;
  opacity: 0;
}
.hidden {
  opacity: 0;
}

@keyframes slideDown {
  0%,
  100% {
    transform: translateX(-50%) translateY(-250px);
    opacity: 1;
  }
  10%,
  90% {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}
</style>
