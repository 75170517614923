import Notification from './components/Notification';

function init({ Vue }) {
  return new Vue({
    el: '#vm__notification',
    components: {
      Notification,
    },
  });
}

export default init;
